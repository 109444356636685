* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	overflow-x: hidden;
	font-family: 'Josefin Sans', sans-serif;
	margin: 20px;
	padding: 0;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}

h1 {
	font-size: 22px;
}

h2 {
	font-size: 20px;
}

h3 {
	font-size: 18px;
}

h4 {
	font-size: 16px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 12px;
}

code {
	font-size: 1.2em;
}

ul {
	padding-inline-start: 20px;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.nav-bar {
	color: white;
	padding: 0;
	margin: -20px;
	margin-bottom: 20px;
	width: auto;
}

.lobster-two-regular {
	font-family: 'Lobster Two', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.lobster-two-bold {
	font-family: 'Lobster Two', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.lobster-two-regular-italic {
	font-family: 'Lobster Two', sans-serif;
	font-weight: 400;
	font-style: italic;
}

.lobster-two-bold-italic {
	font-family: 'Lobster Two', sans-serif;
	font-weight: 700;
	font-style: italic;
}

.josefin-sans-regular {
	font-family: 'Josefin Sans', sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}

@keyframes rotate {
	0% {
		transform: rotate(10deg);
	}
	50% {
		transform: rotate(-10deg); /* Rotate left */
	}
	100% {
		transform: rotate(10deg);
	}
}

.badge-animation {
	display: inline-flex;
	animation: rotate 2.25s ease-in-out infinite; /* Rotate indefinitely */
}
